import { PlasmicCanvasContext } from "@plasmicapp/host";
import clsx from "clsx";
import cookieCutter from "cookie-cutter";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";

import { FormField } from "@sharedComponents/FormField";
import { FormData, SubmittedFormData } from "@sharedInterfaces/FormData.interface";
import { FormContext } from "@sharedLib/formContext";
import { submitForm } from "@sharedLib/formSubmit";
import { modifyUtms } from "@sharedLib/modifyUtms";
import { parseRouter } from "@sharedLib/parseRouter";

import { Box, Button, CircularProgress, Flex } from "@chakra-ui/react";
import setFieldData from "final-form-set-field-data";
import { Form as FinalForm } from "react-final-form";

export const Form = ({
  children,
  className,
  darkBackground = false,
  formFields,
  formName,
  inline = false,
  isPlasmic = false,
  inputColorScheme = "default",
  marketoFormId,
  showHidden = false,
  submitButton = null,
  successMessage,
  usePlaceholders = false,
  onChange = (e) => null,
  onSuccess = () => null,
}: FormData) => {
  /* Get the router object */
  const useRouterSafe = () => {
    try {
      return useRouter();
    } catch (err) {
      return undefined;
    }
  }
  const router = useRouterSafe();
  if (typeof router === "undefined") return null;
  const { basePath, params } = parseRouter(router);

  const inEditor = useContext(PlasmicCanvasContext);

  /* Form data and transforms */
  const [savedFormValues, setSavedFormValues] = useState(null); /* Form values from the formValues cookie */
  const [formValues, setFormValues] = useState([]); /* Form values for the current form */
  const [fieldTransforms, setFieldTransforms] = useState({}); /* Field transforms for the current form */

  /* Individual form field state hooks */
  const [countryValue, setCountryValue] = useState("");
  const [showState, setShowState] = useState(false);

  /* Status hooks */
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  /* UTM and URL parameters */
  const [urlParams, setUrlParams] = useState(null); /* UTM params from the utmParams cookie + query params */
  const [utmCampaign, setUtmCampaign] = useState({}); /* utm_campaign gets specific treatment, hold each version of it (Cookie, URL) here */
    
  /* Process saved form values from the formValues cookie */
  useEffect(() => {
    if (cookieCutter.get("formValues")) {
      if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {
        console.log("Form Cookies: ", cookieCutter.get("formValues"));
      }
      const savedValues = JSON.parse(cookieCutter.get("formValues"));
      setSavedFormValues(savedValues);
      if (savedValues && savedValues["country"]) {
        setCountryValue(savedValues["country"]);
        if (savedValues["country"] === "United States") {
          setShowState(true);
        }
      }
    }
  }, [cookieCutter]);

  /* ZoomInfo FormComplete Hooks */
  useEffect(() => {
    if (!window._zi_fc) {
      window._zi_fc = {}
    }
    window._zi_fc.onReady = (data) => {
      let customTransforms = {};
      // If there are inputs mapped in the form
      if (data?.inputs?.length > 0) {
        // Loop through the mapped inputs
        data.inputs.forEach((input) => {
          // If one of the inputs is a state field
          if (input.toLowerCase().includes("name='state'") || input.toLowerCase().includes("name=\"state\"")) {
            // Flag the form to do special transforms so FormComplete matching works
            customTransforms = {
              ...customTransforms,
              ...{ "state": true },
            };
          }
        });
        
        // Store custom transforms
        setFieldTransforms(fieldTransforms => ({ ...fieldTransforms, ...customTransforms }));
      }
    }
  });

  /* UTM Handling */
  useEffect(() => {
    /* Fetch utmParams cookie, URL parameters from the router query, and formValues cookie for use in form fields */
    const utmParams = cookieCutter.get("utmParams") ? JSON.parse(cookieCutter.get("utmParams")) : {};
    const routerItems = modifyUtms(router.query) || {};

    /* Set utm_campaign separately since it gets a different priority handling for the initial field value */
    setUtmCampaign({
      ...(utmParams?.utm_campaign && { "cookie": utmParams.utm_campaign }),
      ...(routerItems?.utm_campaign && { "url": routerItems.utm_campaign })
    });

    /* Set the urlParams object, omitting utm_campaign */
    setUrlParams({
      ...utmParams,
      ...routerItems,
    });

    /* Remove utm_campaign from the utmParams and routerItems objects */
    setUrlParams(currentParams => {
      const { utm_campaign, ...rest } = currentParams;
      return rest;
    });
  }, [router.query]);

  /* Exit if no fields to render */
  if (!formFields) return null;

  /* Handler Functions */

  /* Form change handler */
  const handleChange = (e) => {
    onChange(e);
    setFormValues({
      ...formValues,
      ...{ [e.target.name]: e.target?.checked ? "true" : e.target?.value },
    });
  };

  /* Country change handler */
  const updateCountryValue = (e) => {
    if (e == "United States") {
      setShowState(true);
    } else {
      setShowState(false);
    }
    setCountryValue(e);
  };

  /* Success handler */
  const handleSuccess = async () => {
    /* Set the form submission status */
    setIsSubmitting(false);
    setIsSubmitted(true);

    /* Run the onSuccess function */
    onSuccess();

    /* Update the url shallowly */
    await router.push(`${basePath + params + (params ? "&" : "?")}form=success`, undefined, { shallow: true });
  };
  
  /* Error handler */
  const handleError = async () => {
    /* Set the form submission status */
    setIsSubmitting(false);

    /* Update the url shallowly */
    await router.push(`${basePath + params + (params ? "&" : "?")}form=error`, undefined, { shallow: true });
  };

  /* Form Submission */
  const onSubmit = async (values) => {
    setIsSubmitting(true);

    const postData = {} as SubmittedFormData;
    postData["fields"] = values;
    postData["formId"] = marketoFormId;
    postData["formName"] = formName;

    submitForm({
      postData: postData,
      fieldTransforms: fieldTransforms,
      formFields: formFields,
      onSuccess: handleSuccess,
      onError: handleError,
    })
  };

  return (
    <FormContext.Provider
      value={{
        // Form config
        darkBackground: darkBackground,
        inline: inline,
        inputColorScheme: inputColorScheme,
        showHidden: showHidden,
        usePlaceholders: usePlaceholders,
        // Form values
        savedFormValues: savedFormValues,
        formValues: formValues,
        fieldTransforms: fieldTransforms,
        countryValue: countryValue,
        showState: showState,
        urlParams: urlParams,
        utmCampaign: utmCampaign,
        // Form handlers
        handleChange: (e) => handleChange(e),
        changeState: (e) => {
          updateCountryValue(e);
        },
      }}
    >
      <FinalForm
        onSubmit={onSubmit}
        mutators={{ setFieldData }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <Flex
            as="form"
            className={className}
            flexDir={{
              base: "column",
              md: (inline ? "row" : "column")
            }}
            onSubmit={handleSubmit}
            width="100%"
            alignItems="flex-start"
            id={`form-${marketoFormId}`}
            data-form-id={marketoFormId}
          >
            {inEditor ? (
              <>
                <Box width="100%" mb="60px">
                  {children}
                </Box>
                <Box>{successMessage}</Box>
              </>
            ) : isSubmitting && !submitButton ? (
              <CircularProgress
                isIndeterminate
                color="#6863F2"
                trackColor="rgba(0,0,0,0)"
              />
            ) : isSubmitted ? (
              successMessage
            ) : isPlasmic ? children : (
              <>
                {formFields.map((fieldData) => (
                  <FormField
                    key={fieldData.fieldName}
                    id={fieldData.fieldName}
                    {...fieldData}
                  />
                ))}
                {!!submitButton && (
                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    loadingText="Submitting..."
                    className={clsx(
                      "EmbeddedForm__button",
                      "flex items-center justify-center ml-auto",
                      {
                        "h-14 px-6 shrink-0": inline,
                        "mt-2 h-11 px-5": !inline,
                      },
                    )}
                    borderRadius={inline && "0"}
                    bg={submitButton?.backgroundColor || "black-01"}
                    color={submitButton?.textColor || "white-01"}
                    _hover={{
                      bg: submitButton?.backgroundColorHover || "purple-01",
                      color: submitButton?.textColorHover || "white-01",
                    }}
                    _focus={{
                      bg: submitButton?.backgroundColorHover || "purple-01",
                      color: submitButton?.textColorHover || "white-01",
                    }}
                    _active={{
                      bg: submitButton?.backgroundColorHover || "purple-01",
                      color: submitButton?.textColorHover || "white-01",
                    }}
                  >
                    {submitButton?.text || "Submit" }
                  </Button>
                )}
              </>
            )}
          </Flex>
        )}
      />
    </FormContext.Provider>
  );
};

export default Form;
