import { FormFieldData, SubmittedFormData } from "@sharedInterfaces/FormData.interface";
import { convertRegion, TO_ABBREVIATED } from "@sharedLib/convertRegion";
import { IncomingWebhook } from "@slack/webhook";
import cookieCutter from "cookie-cutter";

/* Types */
interface IProps {
  postData: SubmittedFormData;
  fieldTransforms?: { [key: string]: boolean }
  formFields: FormFieldData[];
  onSuccess?: () => Promise<void> | void;
  onError?: () => Promise<void> | void;
}

/* Submit the form */
export const submitForm = async ({
  postData,
  fieldTransforms,
  formFields,
  onSuccess = async () => { },
  onError = async () => { }
}: IProps) => {
  /* If no post data or window object, return null */
  if (typeof window === "undefined" || !postData) return null;

  /* Loop through values and perform data transformations */
  Object.keys(postData?.fields).forEach((field) => {
    // if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {
    //   console.log("Field: ", field);
    // }
    // Convert state to abbreviation for Marketo
    if (field === "state" && fieldTransforms["state"] && !!postData?.fields[field]) {
      // console.log("Located State Field: ",values[field]);
      postData.fields[field] = convertRegion(postData?.fields[field], TO_ABBREVIATED);
    }
    // If the field is a date field and has a value, convert it to ISO string for Marketo
    else if (formFields.find((formField) => formField.fieldName === field).fieldType.toLowerCase().includes("datetime") && !!postData?.fields[field]) {
      // console.log("Located DateTime Field: ",values[field]);
      postData.fields[field] = new Date(postData?.fields[field]).toISOString();
    }
  });

  /* Set the form data */
  postData.marketoCookie = cookieCutter.get("_mkto_trk");
  const location = new URL(window.location.href);
  location.searchParams.delete("gclid");
  location.searchParams.delete("gbraid");
  location.searchParams.delete("wbraid");
  location.searchParams.delete("msclkid");
  location.searchParams.delete("mkt_tok");
  postData.pageUrl = location.href;
  postData.fields["referralURL"] = location.href.substring(0, 255);

  /* Fetch analytics tracking data */
  if (window?.ga && process.env.NEXT_PUBLIC_GTM_ID) {
    window.ga(function () {
      const tracker = window.ga.getAll()[0];
      postData.fields["GATRACKID__c"] = tracker.get("trackingId");
      postData.fields["GACLIENTID__c"] = tracker.get("clientId");
      postData.fields["GAUSERID__c"] = tracker.get("userId");
    });
  }
  
  let result;

  /* Debug post data submission, sent to Slack + Marketo */
  if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {
    console.log("Post Data :", postData)
  }

  if (
    process.env.NEXT_PUBLIC_SLACK_WEBHOOK /* Triggers both Slack and Marketo actions */
  ) {
    /* Submit the form to Marketo */
    const post = await fetch(
      `${process.env.NEXT_PUBLIC_CMS_URL}/api/submitForm`,
      {
        body: JSON.stringify(postData),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    /* Fetch the result from Marketo */
    result = await post.json();

    /* Send the result to Slack */
    sendSlackNotification(postData, result);
  } else {
    /* If no Slack webhook is set, just return a success */
    result = { success: true };
  }

  if (result.success === true) {
    /* Set expiration date for cookies */
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 60);

    /* Store visible fields by fieldName: value in an object */
    const storedValues = formFields.reduce((acc, field) => {
      if (!postData?.fields[field.fieldName] || field.fieldType === "hidden") return acc;
      acc[field.fieldName] = postData?.fields[field.fieldName];
      return acc;
    }, {});
    if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {
      console.log("Stored Values: ", storedValues);
    }

    /* Merge the new stored values into the existing cookie values */
    const storedFormValues = {
      ...(cookieCutter.get("formValues") &&
        JSON.parse(cookieCutter.get("formValues"))),
      ...storedValues,
    };

    /* Save the updated cookie */
    cookieCutter.set("formValues", JSON.stringify(storedFormValues), {
      expires: expireDate,
    });

    /* Debug form fill data */
    // if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {
    //   console.log("Form Fill Label :", postData.formName)
    //   console.log("Form Fill Value :", location.href)
    // }

    /* Send conversion events */
    // Fire Google Form Fill Event
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "event",
        eventProps: {
          category: "Form",
          action: "Form Fill",
          label: postData.formName,
          value: location.href,
        },
        formData: {
          email: postData.fields?.email,
          phone: postData.fields?.phone,
          name: postData.fields?.firstName ? `${postData.fields?.firstName} ${postData.fields?.lastName}` : null,
          company: postData.fields?.company,
        }
      });
    }

    // Fire Revsure Form Fill Event
    if (window.rudderanalytics) {
      window.rudderanalytics.identify(postData.fields?.email, {
        email: postData.fields?.email,
        name: `${postData.fields?.firstName} ${postData.fields?.lastName}`,
        company: postData.fields?.company,
        anonymous_id: window.rudderanalytics.getAnonymousId(),
      });
      window.rudderanalytics.track(`Form Submission - ${postData.formName}`);
    }

    /* Fire Qualified Form Fill Event */
    if (typeof qualified === "function") {
      const qualifiedFormData = {
        "email": postData.fields?.email,
        "ae_routing": postData.fields?.AE_Routing__c,
        "campaign_status": postData.fields?.CStatus,
        "company": postData.fields?.company,
        "content_stage": postData.fields?.contentStage,
        "country": postData.fields?.country,
        "engagement_detail_temp": postData.fields?.engagementDetailTemp,
        "first_name": postData.fields?.firstName,
        "gaclientid": postData.fields?.GACLIENTID__c,
        "gatrackid": postData.fields?.GATRACKID__c,
        "gauserid": postData.fields?.GAUSERID__c,
        "gclid": postData.fields?.GCLID__c,
        //"industry": postData.fields?.industry,
        "job_title": postData.fields?.title,
        "last_name": postData.fields?.lastName,
        "marketing_release": postData.fields?.marketingRelease,
        "name": postData.fields?.firstName ? `${postData.fields?.firstName} ${postData.fields?.lastName}` : null,
        "phone": postData.fields?.phone,
        "referral_url": postData.fields?.referralURL,
        //"requested_sequence_name": postData.fields?.requestedSequenceName,
        "state": postData.fields?.state,
        //"status": postData.fields?.status,
        "utm_campaign": postData.fields?.utm_campaign,
        "utm_content": postData.fields?.utm_content,
        "utm_datetime": postData.fields?.utm_datetime__c,
        "utm_medium": postData.fields?.utm_medium,
        "utm_source": postData.fields?.utm_source,
        "utm_term": postData.fields?.utm_term,
        "website": postData.fields?.website,
        "what_would_you_like_to_discuss": postData.fields?.mktoPersonNotes,
      }
      if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {
        console.log("Qualified Form Fill Event");
        console.log("Qualified Form Data: ", qualifiedFormData);
      }
      qualified("saveFormData", qualifiedFormData);
      qualified("emitFormFill", "custom");
    }

    /* Fire the callback fn */
    await onSuccess();

    /* Fire the Qualified Form Experience */
    if (typeof qualified === "function") {
      if (postData?.formId === 1980) {
        if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {  
          console.log("Showing Form Experience");
        }
        qualified("showFormExperience", "experience-1733779726817");
      }
    }
  } else {
    /* Fire the callback fn */
    await onError();
  }

  // TODO: alternatively, fire an event to Google Tag Manager test
};

/* Send a Slack notification */
const sendSlackNotification = (postData: SubmittedFormData, result: any) => {
  const fields = postData.fields;
  const webhookUrl = process.env.NEXT_PUBLIC_SLACK_WEBHOOK;

  if (!webhookUrl && process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {
    console.log("No Slack webhook URL found");
    return;
  }

  const webhook = new IncomingWebhook(webhookUrl);
  delete webhook["axios"].defaults.headers["User-Agent"];
  webhook["axios"].defaults.headers["Content-Type"] =
    "application/x-www-form-urlencoded";

  const fieldsBlocks = [
    postData.fields["email"] ? {
      type: "section",
      text: {
        type: "mrkdwn",
        text: `*email*: ${postData.fields["email"]}`,
      },
    } : {
      type: "header",
      text: {
        type: "plain_text", 
        text: `No Form Data`,
        emoji: true,
      },
    }
  ];    

  Object.keys(postData.fields).forEach((field) => {
    if (!!postData.fields[field] && field !== "email" && field !== "referralURL") {
      fieldsBlocks.push({
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*${field}*: ${postData.fields[field]}`,
        },
      });
    }
  });

  if (result?.result && result.result[0]?.reasons === undefined && postData.fields["email"] !== "error@abnormal.ai") {
    webhook.send({
      attachments: [
        {
          color: "#00ff00",
          blocks: [
            {
              type: "header",
              text: {
                type: "plain_text",
                text: `Form Submission: ${postData.formName}`,
                emoji: true,
              },
            },
            ...fieldsBlocks,
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `*Result:* ${result.result[0].status}`,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `*Form Data:*\n\`\`\`${JSON.stringify(
                  postData
                )}\`\`\``,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `*Referral URL:* ${postData.fields["referralURL"]}\n\n<https://app-sj31.marketo.com/leadDatabase/loadLeadDetail?leadId=${result.result[0].id}|View Lead in Marketo>`,
              },
            },
          ],
        },
      ],
    });
  } else {
    webhook.send({
      attachments: [
        {
          color: "#ff0000",
          blocks: [
            {
              type: "header",
              text: {
                type: "plain_text",
                text: "Website Form Submission Failed",
                emoji: true,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `*Form Name*: ${postData.formName}`,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `*Referral URL:* ${postData.fields["referralURL"]}`,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `*Form Data:*\n\`\`\`${JSON.stringify(
                  postData
                )}\`\`\``,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `*Result:*\n\`\`\`${JSON.stringify(result)}\`\`\`\``,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `*Users Notified:* <@U05JA8KKC4T> <@U021D2PNK1Q>`,
              },
            }
          ],
        },
      ],
    });
  }
}

export default submitForm;
